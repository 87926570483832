<template>
  <div>
    <c-navbar v-if="!hideNavbar">
      <template v-slot:title>
        {{ $t('Orders') }}
      </template>
      <template v-slot:buttons>
        <b-button variant="outline-primary" class="mr-2">{{ $t('Export') }}</b-button>
        <b-button variant="primary" class="mr-2">{{ $t('Create order') }}</b-button>
      </template>
    </c-navbar>
    <b-card no-body>
      <div class="m-2">
        <c-table-filters :available-filters="ordersPagination.filters"
                         module="ordersList"
                         @search="v => searchQuery = v"
                         @filtersUpdate="v => tableFilters = v"></c-table-filters>
      </div>
    </b-card>
    <b-card
        no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-dropdown :text="$t('Tools')" variant="primary" id="settingsDropdown" size="md"
                        :disabled="!selectedRows.length">
              <b-dropdown-item>
                <feather-icon icon="ArchiveIcon"></feather-icon>
                {{ $t('Archiving') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="BoxIcon"></feather-icon>
                {{ $t('Restore from archive') }}
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="CheckCircleIcon"></feather-icon>
                {{ $t('Mark as completed') }}
              </b-dropdown-item>
            </b-dropdown>
            <c-vue-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>

      </div>

      <c-table
          ref="refOrdersListTable"
          :items="ordersPagination.data"
          head-variant="light"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          has-selection
          selectable
          @rowSelected="v => selectedRows = v"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <!-- Column: Id -->
        <template #cell(id)="data">
          <div class="d-flex flex-column">
            <div class="col">
              <b-link
                  :to="{ name: 'orders.view', params: { id: _.get(data, 'item.id') }}"
                  class="font-weight-bold"
              >
                {{ $t('Order') }} <span>#{{ _.get(data, 'item.id') }}</span>
              </b-link>
            </div>
            <div class="col">
              {{ $t('from') }}
              <span> {{ _.get(data, 'item.customer_first_name') }} {{ _.get(data, 'item.customer_last_name') }}</span>
            </div>
          </div>

        </template>


        <!-- Column: Issued Date -->
        <template #cell(address)="data">
        <span>
          {{ _.get(data, 'item.address.city') }}
        </span>
        </template>

        <template #cell(created_at)="data">
          <div class="d-flex flex-column">
            <span>{{ moment.utc(_.get(data, 'item.created_at.date')).format('DD.MM.Y') }}</span>
            <span class="text-muted">{{ moment.utc(_.get(data, 'item.created_at.date')).format('HH:mm') }}</span>
          </div>
        </template>

        <!-- Column: Balance -->
        <template #cell(total)="data">
          <template>
            <price :value="_.get(data, 'item.total')" from-cents></price>
          </template>
        </template>

        <template #cell(status)="data">
          <template>
            {{ resolveInvoiceStatusVariantAndIcon(_.get(data, 'item.status')).text }}
          </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <div class="d-flex flex-column">
              <feather-icon icon="FileIcon" size="20" :class="{'text-muted': !data.item.note_customer}"
                            v-b-tooltip.hover :title="data.item.note_customer"/>
              <feather-icon icon="FileIcon" size="20" :class="{'text-muted': !data.item.note_administrator}"
                            v-b-tooltip.hover :title="data.item.note_administrator"/>
            </div>
          </div>
        </template>
        <template #head(actions)>
          <feather-icon icon="FileIcon" size="20"></feather-icon>
        </template>
      </c-table>
      <c-pagination :data="ordersPagination" :per-page="perPage" :page="page"
                    @pageChange="v => page = v"></c-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BDropdown, BDropdownItem,
  BLink,
  VBTooltip,
  BSpinner, BButton,
} from 'bootstrap-vue'
import {mapActions} from 'vuex'
import CNavbar from "@/views/cloudcart/_elements/CNavbar";
import CTable from "@/views/cloudcart/_elements/CTable";
import CPagination from "@/views/cloudcart/_elements/CPagination";
import Price from "@/views/cloudcart/_elements/Price";
import CTableFilters from "@/views/cloudcart/_elements/CTableFilters";
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";

export default {
  name: "OrdersList",
  props: {
    hideNavbar: {
      type: Boolean,
      default: false
    }
  },
  directives: {'b-tooltip': VBTooltip},
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BLink,
    BSpinner,
    BDropdown,
    BDropdownItem,
    CNavbar,
    CTable,
    CPagination,
    Price,
    CTableFilters,
    CVueSelect
  },
  data() {
    return {
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      page: 1,
      searchQuery: '',
      tableColumns: [
        {key: 'selection', sortable: false, label: this.$t('Selection')},
        {key: 'id', label: this.$t('Order'), sortable: true},
        {key: 'address', label: this.$t('Address'), sortable: true},
        {key: 'created_at', label: this.$t('Date'), sortable: true},

        {key: 'issuedDate', label: this.$t('Received at'), sortable: true},
        {key: 'status', label: this.$t('Status'), sortable: true},
        {key: 'total', label: this.$t('Order total'), sortable: true},
        {key: 'actions', label: this.$t('Actions')},
      ],
      isSortDirDesc: true,
      sortBy: 'id',
      selectedRows: [],
      tableFilters: []
    }
  },
  created() {
    this.fetchOrders({
      perPage: this.perPage,
      page: this.page,
      filters: this.tableFilters
    });
  },
  computed: {
    ordersPagination() {
      return this.$store.getters['orders/paginatedOrders']
    }
  },
  methods: {
    ...mapActions('orders', ['fetchOrders']),
    resolveInvoiceStatusVariantAndIcon(status) {
      if (status === 'pending') return {variant: 'warning', icon: 'PieChartIcon', text: this.$t('Pending')}
      if (status === 'paid') return {variant: 'success', icon: 'CheckCircleIcon', text: this.$t('Paid')}
      if (status === 'draft') return {variant: 'primary', icon: 'SaveIcon', text: this.$t('Draft')}
      if (status === 'sent') return {variant: 'secondary', icon: 'SendIcon', text: this.$t('Sent')}
      if (status === 'completed') return {variant: 'danger', icon: 'InfoIcon', text: this.$t('Completed')}
      if (status === 'refunded') return {variant: 'danger', icon: 'InfoIcon', text: this.$t('Refunded')}
      if (status === 'failed') return {variant: 'danger', icon: 'InfoIcon', text: this.$t('Failed')}
      if (status === 'canceled') return {variant: 'danger', icon: 'InfoIcon', text: this.$t('Canceled')}
      if (status === 'cancelled') return {variant: 'danger', icon: 'InfoIcon', text: this.$t('Cancelled')}
      return {variant: 'secondary', icon: 'XIcon', text: status}
    }
  },
  watch: {
    perPage(newValue) {
      this.fetchOrders({
        perPage: newValue,
        page: this.page,
        filter: this.searchQuery,
        filters: this.tableFilters
      });
    },
    page(newValue) {
      this.fetchOrders({
        perPage: this.perPage,
        page: newValue,
        filter: this.searchQuery,
        filters: this.tableFilters
      });
    },
    searchQuery: window._.debounce(function (newValue) {
      this.fetchOrders({
        perPage: this.perPage,
        page: 1,
        filter: newValue,
        filters: this.tableFilters
      });
    }, 300),
    tableFilters(newValue) {
      this.fetchOrders({
        filters: newValue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

[dir] .table th, [dir] .table td {
  padding: 0.52rem 1rem;
}
</style>
